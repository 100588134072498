.company-stats {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .company-stats-header {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }

  .company-stats-content {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;
  }
}