.location-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .location-stats-header {
    display: flex;
    gap: 10px;
    justify-content: center;

  }

  .location-stats-content {
    margin-top: 10px;
  }
}