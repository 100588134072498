@import '../../styles/stylingVariables.scss';

#donut-chart {
  width: 360px;
  height: 360px;

  & .apexcharts-legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    .apexcharts-legend-text {
      color: $neutrals-30 !important;
    }

    &>.apexcharts-legend-series:nth-of-type(1) {
      margin: 0 !important;

      &>.apexcharts-legend-marker {
        background: $secondary-100 !important;
        color: $secondary-100 !important;
      }
    }

    &>.apexcharts-legend-series:nth-of-type(2) {
      margin: 0 !important;

      &>.apexcharts-legend-marker {
        background: $primary-100 !important;
        color: $primary-100 !important;
      }
    }
  }

  & .apexcharts-legend-text {
    display: flex;
    gap: 3px;

    &>div {
      font-weight: 600;
    }
  }

  & .apexcharts-tooltip {
    border-radius: 10px;
    background: none;
    box-shadow: none;

    &>.apexcharts-tooltip-series-group {
      border: 1px solid $neutrals-50;
      box-shadow: none;
      outline: none;
      border-radius: 10px;
      padding-bottom: 0;
      color: $neutrals-30;
      background-color: $neutrals-90;

      & .apexcharts-tooltip-y-group {
        padding: 9px 0;

        &>.apexcharts-tooltip-text-y-value {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          display: flex;
          gap: 5px;
        }
      }
    }
  }

  .apexcharts-series {
    .apexcharts-donut-slice-1 {
      fill: $primary-100 !important;

      &:hover {
        fill: $primary-90 !important;
      }
    }

    .apexcharts-donut-slice-0 {
      fill: $secondary-100 !important;

      &:hover {
        fill: $secondary-90 !important;
      }
    }
  }

}